.TableCell {
  color: black !important;
  font-size: 12px;
  height: 44px;
  padding: 0px;
  font-weight: bold !important;
}
.css-veaon5-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 2px !important;
}

.text_area_bank_add_transaction {
  min-width: 350px !important;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaders {
  min-height: 40px !important;
  max-height: 45px !important;
}

.MuiDataGrid-main .MuiDataGrid-virtualScroller {
  margin-top: 45px !important;
}

.bankStatementSearchContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.assignBankStatementBar {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-top: 5px;
}
.bankTransactionAddBtn {
  background: #ff5a01;
  margin-left: 5% !important;
  color: white;
  border-radius: 20px;
  padding: 5px 20px;
  width: 91px;
}
.bankStatementBtn {
  width: 83px;
}
.bankStatementInitiateBtn {
  width: 150px;
}
.addBankTransactionReset {
  background-color: white !important;
  width: 90px;
  gap: 8px;
  color: black !important;
  border: 1px solid #bcbdbf !important;
}
.addBankTransactionSubmit {
  background-color: #ff5a01 !important;
}
.EditPageContainer {
  margin-top: 5;
  padding: 12px 0px 18px 15px;
  cursor: default;
}
.EditpageContainerItems {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.EditPageContainerTransactions {
  padding: 10px;
  cursor: default;
  width: 100%;
  margin-left: -10px;
}
.FileUploadIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.EditPageContainerTransactionsList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.resetSubmit {
  justify-content: flex-end;
  padding-top: 0px;
  margin-right: 12px;
}
.PaymentReferenceDialogue {
  min-width: 350px;
  width: 100%;
  padding: 5px;
  overflow: hidden;
}
.CloseIconStyle {
  background-color: #3b3f44;
  color: #ffff;
  height: 16px !important;
  width: 16px !important;
  padding: 7px 8px;
  border-radius: 32px;
}

.hide-column{
  display: none;
}

.exception-details {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px 20px;
  background-color: #f5f5f5;
  z-index: 10;
  text-transform: capitalize;
  margin-top: 15px;
}

.table-checkbox {
  padding: 0 5px;
}

.overall-status-cell.MuiDataGrid-cell{
  justify-content: left !important;
}

.amount-table-cell.MuiDataGrid-cell{
  justify-content: right !important;
}

.table_row th{
  position: inherit !important;
  background-color: #ffc619;
}

.table_row th button{ 
  background-color: transparent !important;
  color: #000;
  border-left: 1px solid #fff;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.table_row td{
  position: inherit !important;
}

.collapse-table-container {
  margin-left: 2.3%;
  overflow-x: auto;
}

.table_row {
  box-shadow: none !important;
}

.add_user_button {
    width: 25px !important;
    font-size: 12px !important;
}

.user_id_column {
 width: 60px
}

.user_name_column {
    width: 300px
}