.autoComplete {
    width: 326px !important;

    .MuiFormControl-root {
        background-color: transparent;
        position: relative;
        margin: 0;
    }

    .MuiOutlinedInput-root {
        padding: 0;
        padding-left: 10px;
        height: 40px;
        
    }

    .MuiInputLabel-root {
        color: black !important;
        position: absolute;
        top: -5px;
    }

    .MuiInputLabel-shrink, legend{
        display: none;
    }

    input {
        position: absolute;
        min-width: 80% !important;
    }
}

.inputField {
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    min-width: 100px;
    max-width: auto;
    padding-left: 10px !important;
    &:hover,
    &:active {
        border: 1px solid #FF5A01 !important;
    }
    
    &:focus-visible {
        outline: 1px solid #FF5A01 !important;
    }
}