/* Sidebar */
.sidebar {
    box-sizing: border-box;
    background:  #FAFAFA;
    color: black;
    overflow-y: auto;
    overflow-x: hidden;
    border-top-left-radius: 10px;
    height: calc(100vh - 152px);
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1), 5px 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 18px; */
  }
  .logo {
    margin-top:30px;
    font-size: 20px;
    line-height: 0;
  }
  .bars {
    width: 20px;
  }
  .hide {
    display: none;
  }
  
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
  }
  .search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
  
    color: white;
  }
  .routes {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    /* gap: 14px; */
    list-style: none;
  }
  .link {
    display: flex;
    color:black;
    /* gap: 10px; */
    padding: 7px 7px;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
    align-items: center;
    font-size: 14px;
    /* justify-content: center; */
  }
  .linkss {
    display: flex;
    color: white;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
  }
  .links {
    /* display: flex; */
    color: white;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    text-decoration: none;
    position: relative;
    display: inline-block;
  }
  .link:hover {
    border-right: 4px solid white;
    background: #1D1F22;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .linkss:hover {
    border-right: 4px solid white;
    background: rgb(45, 51, 89);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .list {
    text-decoration: underline;
  }
  .list:hover {
    border-right: 4px solid white;
    background: rgb(45, 51, 89);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link.active {
    border-radius: 5px;
    background: #1D1F22;
    color:white;
    border-left: 4px solid red;
  }
  .link_text {
    white-space: nowrap;
    font-size: 14px;
    text-decoration: none;
    font-style: italic;
  }
  
  .menu {
    display: flex;
    color: white;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }
  .menu_item {
    display: flex;
    gap: 10px;
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
  }
  .main{
    position: absolute;
  }
  .menu-list {
    position: relative;
    top: 0;
    left: 200px;
    background-color: red;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    z-index: 999;
  }
  .menu-list li {
    padding: 4px;
    cursor: pointer;
  }
  
  .menu-list li:hover {
    background-color: #ddd;
  }

  .Subroutes{
    background-color: white;
    padding:inherit;
    
  }
  .Subroutes:hover{
    color:white;
  }
  .administration-icons {
    height:24px;
    width:24px;
  }
  