@import "../scss/variables.scss";

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(15vh - 38px);
  margin-right: 30px;

  @media (min-width: 768px) {
    // override styles
  }

  @media (min-width: 992px) {
    // override styles
  }

  @media (min-width: 1024px) {
    // override styles
  }

  .loginImageStyle {
    height: 70vh;
    width: 35vw;
    border-radius: 10px 0px 0px 10px;

    @media (min-width: 768px) {
      height: 65vh;
      width: 45vw;
    }

    @media (min-width: 992px) {
      height: 65vh;
    }

    @media (min-width: 1024px) {
      height: 70vh;
      width: 35vw;
    }
  }

  .signinContainer {
    background-color: $info-color;
    border-radius: 0px 10px 10px 0px;
    height: 70vh;
    width: 35vw;

    @media (min-width: 768px) {
      height: 65vh;
      width: 45vw;
    }

    @media (min-width: 992px) {
      height: 65vh;
    }

    @media (min-width: 1024px) {
      height: 70vh;
      width: 35vw;
    }

    .letsGetStartText {
      font-size: 30px;
      line-height: 49.72px;
      margin-left: 40px;
      width: 281px;
      margin-bottom: 10px;
      margin-top: 80px;
      font-family: Orgon-Thin;

      @media (min-width: 768px) {
        font-size: 23px;
        width: 265px;
        margin-bottom: 12px;
        margin-top: 10px;
      }

      @media (min-width: 992px) {
        margin-top: 75px;
      }

      @media (min-width: 1024px) {
        font-size: 30px;
        line-height: 49.72px;
        margin-left: 40px;
        width: 281px;
        margin-bottom: 10px;
        margin-top: 80px;
        font-family: Orgon-Thin;
      }
    }

    .signinContinueText {
      color: $primary-color;
      font-size: 20px;
      margin-left: 40px;

      @media (min-width: 768px) {
        font-size: 17px;
      }

      @media (min-width: 992px) {
        font-size: 17px;
      }

      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }

    .formValidator {
      display: flex;
      flex-direction: column;
      padding-left: 40px;
      width: 90%;
      height: 56px;
      position: relative;

      @media (min-width: 768px) {
        width: 95%;
      }

      @media (min-width: 992px) {
        width: 95%;
      }

      @media (min-width: 1024px) {
        width: 90%;
      }
    }

    .formControlLableStyle {
      margin-bottom: 12px;
      max-width: 288px;
      color: black;

      @media (min-width: 768px) {
      }

      @media (min-width: 992px) {
        display: block;
      }

      @media (min-width: 1024px) {
        display: block;
      }
    }

    .rememberForgotPasswordContainer {
      flex-wrap: wrap;
      justify-content: space-between;
      width: 81%;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
        // width: 86%;
      }

      @media (min-width: 1440px) {
        width: 80%;
      }
    }

    .forgotButton {
      color: $secondary-color;
      margin-bottom: 12px;
      padding: 10px;
      text-transform: none;
      background: none;
      border: none;

      &:hover {
        color: $info-color;
        @include backgroundColorProperty();
      }

      @media (min-width: 768px) {
        font-size: 13px;
      }
    }

    .signInFlexBox {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }

    .signInButton {
      width: 80%;
      padding: 10px 0px !important;

      &:hover {
        @include backgroundColorProperty();
      }
    }
  }
}
