@import '../scss/variables.scss';

.nav-link {
    color: $secondary-color;
}

.nav-link:hover {
    color: $primary-color;
}

.activeLinkStyle {
    color: $black-color;
}

a:hover {
    color: $primary-color;
}
