
.filesandfolder .customform {
    width: 100%;
}

.filesandfolder {
    padding: 15px;
}

.filesandfolder form.customform.search .MuiAutocomplete-root {
    width: 200px !important;
}

.filesandfolder form.customform.search .MuiFormControl-root.MuiTextField-root.searchfilefolder {
    width: 250px;
    float: right;
}

.breakall-word {
    word-break: break-all;
}

.filesandfolder .singlefilefol {
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 25px 15px;
    position: relative;

}

.folderfileName .material-icons {
    color: #8aa3ff;
    margin-right: 5px;
}

.filesandfolder .material-icons.more_horiz {
    position: absolute;
    top: 2px;
    right: 10px;
    color: rgba(196, 196, 196, 0.5);
    cursor: pointer;
}

.filesandfolder .folderfileName {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
}

.folderfileDetail {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.5);
}

.folderfileName .material-icons.star {
    color: rgba(196, 196, 196, 0.5);
    margin-left: 20px;
    margin-right: 0px;
}

.folderfileName>span:not(.material-icons) {
    max-width: 90%;
    line-break: anywhere;
}
form.customform .dropdown span.MuiChip-label.MuiChip-labelMedium {
    color: #fff;
}
form.leadsearch.customform .MuiGrid-root.MuiGrid-item {
    padding-left: 20px !important;
    padding-top: 15px;
}

form.customform.leadsearch button.MuiButton-root.MuiButton-contained.whitebg {
    margin-top: 0;
    margin-bottom: 20px;
}
form.leadsearch.customform .MuiFormControl-root.MuiTextField-root,
form.leadsearch.customform .MuiAutocomplete-root {
    margin-bottom: 0px;
}

form.customform.leadsearch button.MuiButton-root.MuiButton-contained.whitebg {
    margin-top: 25px;
}
.leadsearch {
    width: 100%;
}

.leadsearch>.MuiGrid-root {
    padding: 20px;
    padding-bottom: 0px;
}

.filesandfolder .customform {
    width: 100%;
}
.filesandfolder {
    padding: 15px;
}
.filesandfolder form.customform.search .MuiAutocomplete-root {
    width: 200px !important;
}
.filesandfolder form.customform.search .MuiFormControl-root.MuiTextField-root.searchfilefolder {
    width: 250px;
    float: right;
}
.breakall-word {
    word-break: break-all;
}
.filesandfolder .singlefilefol {
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 25px 15px;
    position: relative;
}
.folderfileName .material-icons {
    color: #8AA3FF;
    margin-right: 5px;
}
.filesandfolder .material-icons.more_horiz {
    position: absolute;
    top: 2px;
    right: 10px;
    color: rgba(196, 196, 196, 0.5);
    cursor: pointer;
}
.filesandfolder .folderfileName {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
}
.folderfileDetail {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.5);
}
.folderfileName .material-icons.star {
    color: rgba(196, 196, 196, 0.5);
    margin-left: 20px;
    margin-right: 0px;
}
.folderfileName>span:not(.material-icons) {
    max-width: 90%;
    line-break: anywhere;
}
form.customform .dropdown span.MuiChip-label.MuiChip-labelMedium {
    color: #fff;
}
.leadsearch {
    width: 100%;
}
.leadsearch>.MuiGrid-root {
    padding: 20px;
    padding-bottom: 0px;
}
form.leadsearch.customform button.MuiButton-root.MuiButton-contained.whitebg {
    width: 100%;
}
form.customform.leadsearch button.MuiButton-root.MuiButton-contained.whitebg {
    margin-top: 25px;
}
form.leadsearch.customform>.MuiGrid-container>.MuiGrid-item:not(:first-child) {
    padding-left: 15px;
}
form.customform:not(.leadsearch) .MuiAutocomplete-root {
    margin-bottom: 0;
}
form.customform:not(.leadsearch) label {
    padding-bottom: 6px;
    display: block;
}
form.leadsearch.customform .MuiGrid-root.MuiGrid-item {
    padding-left: 20px !important;
    padding-top: 15px;
}
form.customform.leadsearch button.MuiButton-root.MuiButton-contained.whitebg {
    margin-top: 0;
    margin-bottom: 20px;
}
form.leadsearch.customform .MuiFormControl-root.MuiTextField-root,
form.leadsearch.customform .MuiAutocomplete-root {
    margin-bottom: 0px;
}
form.customform.leadsearch button.MuiButton-root.MuiButton-contained.whitebg {
    margin-top: 25px;
}
form.customform .dropdown .MuiAutocomplete-input {
    padding: 16.5px 14px !important;
    height: 1.4375em !important;
}
form.customform .dropdown1 .MuiAutocomplete-input {
    height: 1.4375em !important;
    width: 100%;
    margin-top: -4px;
}
.headerdropdown {
    padding: 0px;
}
.dropdownstyle {
    margin-left: 10px;
}
form.customform .dropdown .MuiOutlinedInput-root.MuiInputBase-root {
    padding: 0px !important;
    background: #232A3B !important;
    border-radius: 4px !important;
    color: rgba(255, 255, 255, 0.5);
    height:45px;

}
form.customform .dropdown span.MuiChip-label.MuiChip-labelMedium {
    color: #fff;
}
form.customform .dropdown .Mui-disabled>div {
    display: none;
}
.MuiAutocomplete-hasClearIcon.dropdown.multiple label {
    display: none !important;
}

.split-header .MuiDataGrid-columnHeaderTitle{
    text-wrap: wrap !important;
    line-height: normal;
    /* white-space: pre-wrap !important; */
  }
