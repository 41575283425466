.TableCell {
  color: black !important;
  font-size: 12px;
  height: 44px;
  padding: 0px;
  font-weight: bold !important;
}
.css-veaon5-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 2px !important;
}

.text_area_bank_add_transaction {
  min-width: 350px !important;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaders {
  min-height: 40px !important;
  max-height: 45px !important;
}

.MuiDataGrid-main .MuiDataGrid-virtualScroller {
  margin-top: 45px !important;
}

.bankStatementSearchContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.assignBankStatementBar {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-top: 5px;
}
.bankTransactionAddBtn {
  background: #ff5a01;
  margin-left: 5% !important;
  color: white;
  border-radius: 20px;
  padding: 5px 20px;
  width: 91px;
}
.bankStatementBtn {
  width: 83px;
}
.addBankTransactionReset {
  background-color: white !important;
  width: 90px;
  gap: 8px;
  color: black !important;
  border: 1px solid #bcbdbf !important;
}
.addBankTransactionSubmit {
  background-color: #ff5a01;
}
.EditPageContainer {
  margin-top: 5;
  padding: 12px 0px 18px 15px;
  cursor: default;
}
.EditpageContainerItems {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.EditPageContainerTransactions {
  padding: 10px;
  cursor: default;
  width: 100%;
  margin-left: -10px;
}
.FileUploadIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.EditPageContainerTransactionsList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.resetSubmit {
  justify-content: flex-end;
  padding-top: 0px;
  margin-right: 12px;
}
.PaymentReferenceDialogue {
  min-width: 350px;
  width: 100%;
  padding: 5px;
  overflow: hidden;
}
.CloseIconStyle {
  background-color: #3b3f44;
  color: #ffff;
  height: 16px !important;
  width: 16px !important;
  padding: 7px 8px;
  border-radius: 32px;
}

.split-header .MuiDataGrid-columnHeaderTitle{
  text-wrap: wrap !important;
  line-height: normal;
  /* white-space: pre-wrap !important; */
}