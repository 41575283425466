body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  font-family: "Arial", sans-serif;
  background-color: #e6e6e6;
}

.container {
  display: flex;
  width: "100vw";
  margin: auto;
}

.MuiFormControl-root-MuiTextField-root {
  background-color: white;
}

.container {
  display: flex;
  width: "100vw";
  margin: auto;
}

.custom-table {
  border: 1px solid white;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid white;
}

.navbar-line {
  height: 1px;
  background-color: #fcf7f7;
  width: 100%;
}

.user-detail > *,
.user-detail {
  position: relative;
  margin-left: 10px;
  display: inline-flex;
}

.user-detail:after {
  content: "";
  background-image: url("./assets/Images/drop-arrow.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 25%;
  right: -30px;
  cursor: pointer;
}

.user-detail:after {
  content: "";
  background-image: url("./assets/Images/drop-arrow.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 25%;
  right: -30px;
  cursor: pointer;
}

.header-main .user-detail:after {
  display: none;
}

.user-detail {
  position: relative;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiIconButton-root:focus {
  outline: none;
}

.Toastify__progress-bar {
  transform-origin: right;
}

.MuiDataGrid-columnHeader {
  background: #ffc000;
}

.MuiDataGrid-columnHeaderTitle {
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-family: "Orgon-ExtraBold" !important;
}

.MuiDataGrid-cell {
  display: flex !important;
  justify-content: center !important;
  padding: 0px 3px !important;
}

.bankStatementsList
  .MuiDataGrid-row:has([data-field="Broker_Branch"])
  .MuiDataGrid-cell {
  justify-content: flex-start !important;
}

div[data-field="Broker_Branch"] {
  justify-content: flex-start !important;
}

div[data-field="email"] {
  justify-content: flex-start !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.MuiTableCell-root {
  padding: 5px !important;
  text-wrap: nowrap;
}

button:focus {
  outline: none;
}

.css-1e0d89p-MuiButtonBase-root-MuiIconButton-root {
  margin: -5px !important;
}

.cash-alloc-indicator.historical {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #808080;
  text-align: center;
}

.cash-alloc-indicator.non-historical {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #FFBF00;
  text-align: center;
}

.right-align .MuiInputBase-input {
  text-align: right;
}

.amount-table-cell.MuiDataGrid-cell{
  justify-content: right !important;
}

.main-box{
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.grid-item{
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-box >div{
  width: 100px;
  padding: 0 10px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
}
.MuiDateCalendar-root{
  height: auto !important;
}
.MuiDateCalendar-viewTransitionContainer button{color: #000;}