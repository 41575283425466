.icons {
  border-radius: 50%;
  float: right;
}
.fileIcon img{
  height: 20px;
  width: 24px;
}

.mobileIcon img{
  height: 24px;
  width: 24px;
}
.transaction-icon{
  width: 24px;
  height: 24px;
  margin-left: 0px;
}
.error-icon{
  width: 15px !important;
  height: 15px !important;
  margin: 0px 0px 17px -13px;
}
.success-icon{
  width: 12px !important;
  height: 12px !important;
  margin: 0px 0px 17px -13px;
}

.card-container {
  margin: 10px 0px 0px 0px;
}

.commonText-Value {
  padding-top: 40px;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.text-size{
  font-size: 13px !important;
  /* font-weight: 700 !important; */
}
.widgetTitle{
  margin-top: 0px;
  background-color: #0da59d;
  width: fit-content;
  border-radius: 10px 5px 5px 0px;
  padding: 5px 10px;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.employeeAllocationContainer {
  margin: 10px;
}

.legend {
  height: 20px;
  width: 20px;
}

.legendLabel {
  height: 15px;
  width: 15px;
  border-radius: 5px;
  display: inline-block;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  font-weight: 700;
  text-decoration: underline;
}

.star.green {
  fill: #00f73d !important;
}

.star.yellow {
  fill: #f7f641 !important;
}

.star.red {
  fill: #f70013 !important;
}

.select-daterange-text{
  color: #000 !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
}

.css-15wwp11-MuiTableHead-root {
  position: sticky;
  top: 0px;
  z-index:2;
}

.tiles-Container {
  cursor: pointer;
}

.star {
  fill:grey !important
}
