.customDateRangeContainer {
  z-index: 5;
  position: relative;
  max-width: 200px;
  min-width: 190px;

  .customDateRangeInputContainer {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    .customDateRangeInput {
      font-size: 11px;
      background: white;
      // border: 1px solid #cacaca;
      border: 2px solid #ccc;
      border-radius: 4px;
      height: 18px;
      width: 190px;
      color: #ee3f00;
      padding: 8px 5px;
      outline: none;

      &:focus {
        outline: none;
        border: 1px solid #424242;
        &::placeholder {
          color: #ee3f00;
        }
      }

      &::placeholder {
        color: #757575;
      }

      &:hover {
        border: 1px solid #424242;
        &::placeholder {
          color: #ee3f00;
        }
      }
    }

    .inputError {
      border: 1px solid red !important;
    }

    .customDateRangeIcon {
      position: absolute;
      top: 8px;
      right: 5px;
      cursor: pointer;
    }
  }

  .customDateRangeCard {
    position: absolute;
    background: white;
    top: 40px;
    right: 50;
    border: 0.5px solid #f2f2f2;
    border-radius: 1rem;

    .options-btn, .transparent-btn {
      float: right;
      border: none;
      color: #ee3f00;
      background: transparent;

      &:hover {
        color: #ee3f00a6;
      }

      &:focus {
        outline: none;
      }
    }

    .options-btn {
      width: 100px;
      padding: 0rem !important;
      margin: 0px 0.2rem 0.2rem;
      font-size: 14px;
    }

    .transparent-btn {
      text-decoration: underline;
      padding: 0rem 0.5rem !important;
      margin: 0px 1rem 0.6rem;
    }
  }

  .customDateRange {
    background: white;

    .rdrCalendarWrapper {
      background-color: orange;
      border: none;
      color: #ee3f00;
    }
  }

  .rdrDayNumber {
    span {
      color: #000;
    }
  }

  .rdrMonthAndYearPickers select {
    color: #000;
    background-color: white;
    cursor: pointer;

    &:hover {
      background-color: white;
    }
  }

  .rdrWeekDay {
    color: #000;
  }

  .rdrDayPassive {
    visibility: hidden;
  }

  .rdrMonth {
    padding: 0;
  }

  .rdrDay {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      color: #ee3f00 !important;
    }
  }

  .rdrDayInPreview {
    border-bottom: 1px solid #ee3f00;
    border-top: 1px solid #ee3f00;
  }

  .rdrDayStartPreview {
    @extend .rdrDayInPreview;
    border-left: 1px solid #ee3f00;
  }

  .rdrDayEndPreview {
    @extend .rdrDayInPreview;
    border-right: 1px solid #ee3f00;
  }

  .rdrDayStartOfWeek {
    .rdrDayInPreview {
      border-left: 1px solid #ee3f00;
    }
  }

  .rdrDayEndOfWeek {
    .rdrDayInPreview {
      border-right: 1px solid #ee3f00;
    }
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: #ee3f00;
  }

  .rdrDayDisabled {
    background-color: #fff;
  }

  .rdrNextPrevButton {
    background-color: transparent !important;
  }

  .rdrPprevButton i {
    border-color: transparent #ee3f00 transparent transparent;
    border-width: 6px 8px 6px 6px;
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent #ee3f00;
    border-width: 6px 6px 6px 8px;
  }

  .disableNext {
    .rdrNextButton {
      cursor: default;

      i {
        border-color: transparent transparent transparent #5d5d5d;
      }
    }
  }

  // select {
  //     @extend .scrollBar;
  // }
}

.customfilterDateRange {
  @extend .customDateRangeContainer;
  background: white !important;

  .rdrMonth {
    width: auto;
  }

  .rdrCalendarWrapper {
    background-color: orange;
    border: none;
    color: #ee3f00;
  }

}

.rdrMonthAndYearWrapper {
  height: 40px !important;
  padding: 0px !important;
}