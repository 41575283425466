::-webkit-scrollbar {
  width: 10px;
  padding: 0 4px 0 0;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  width: 6px;
  border: 4px solid #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-width: 1px;
  cursor: pointer;
}
body {
  --page-zoom: 0.8;
}

.file-name {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
}

.cash-allocation {
  &-page {
    width: 100%;
    padding: 16px 16px 8px 16px;
    height: calc(100vh - 150px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    .top-details-section {
      zoom: var(--page-zoom);
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 400;
      margin-bottom: 10px;
      .page-title-container {
        display: flex;
        justify-content: flex-start;
        .page-title {
          color: #ff5a01;
          font-size: 24px;
        }
      }
    }

    .transaction-details {
      .transaction-details-header {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .switch-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          max-width: 800px;

          .top-amount-details {
            margin-left: 20px;
            & > span {
              font-weight: bold;
            }
          }
        }
        .file-download-icon {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          max-width: 400px;
          cursor: pointer;
          &:hover {
            color: blue;
          }
        }
      }
      .transaction-details-card {
        border: 1px solid #ddd;
        border-radius: 7px;
        box-shadow: 0 2px 4px #ddd;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 5px 15px;
        .transaction-item {
          color: black;
          padding: 5px 10px;
          width: 25%;
          box-sizing: border-box;
          display: flex;
          &.w-50 {
            width: 50%;
            .transaction-item-label {
              min-width: 21%;
            }
          }
          .transaction-item-label {
            min-width: 42%;
            display: inline-block;
          }
          .transaction-item-value {
            font-weight: bold;
            padding-left: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .action-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        margin: 0;
      }

      .table-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }

    .action-button {
      padding: 3px 10px;
      background-color: #f2f1f1;
      color: rgb(61, 61, 204);
      border-radius: 0;
      &.pill {
        border-radius: 20px;
        padding: 3px 20px;
      }
      &.danger {
        color: #ff5a01;
        &.filled {
          background: #ff5a01;
          color: white;
        }
      }
      &.bordered {
        border: 1px solid;
      }
      &.info {
        background: #ff5a01;
        color: white;
      }
      &.gap {
        margin: 0 10px;
      }
    }

    .table-section {
      display: flex;
      flex-direction: column;
      background-color: white;
      zoom: var(--page-zoom);
      .allocation-table {
        .table-head {
          background-color: #262671;
          color: white;
          position: sticky;
          top: 0;
          z-index: 10;
          th {
            padding: 5px 10px;
            position: sticky;
            top: 0;
            z-index: 10;
            background-color: #ffc000;
            color: black;
            font-weight: 800;
            text-align: center;
            font-family: Poppins, sans-serif;
            font-size: 13.5px;
            .select-all {
              background-color: #ddd;
              margin: 3px;
            }
          }
        }
        .table-body {
          .boldRow {
            td{
              input {
                font-weight: 700 !important;
              }  
              select {
                font-weight: 700 !important;
              }
            }
          }
          .table-row {
            margin: 0;
            td {
              border: 1px solid #ddd;
              height: 20px;
              padding: 3px;

              .table-checkbox {
                padding: 0 5px;
              }
              .table-input {
                width: 100%;
                input {
                  height: 20px !important;
                  padding: 3px 10px;
                }
                fieldset {
                  border: none;
                }
              }
              select {
                border: none;
              }
              .action-button {
                padding: 0 5px;
                background-color: transparent;
              }
              button {
                height: 26px;
              }
            }
          }
          border-bottom: 2px solid #262671;
        }
        td,
        th {
          color: white;
          font-size: 12px;
          height: 44px;

          &.p-0 {
            padding: 0;
          }
          .action-button {
            border-radius: 20px;
            padding: 6px 6px;
            min-width: unset;
            margin: 0 auto;
          }
        }
      }
      .policy-details {
        display: flex;
        justify-content: space-between;
        padding: 25px 20px 20px;
        background-color: #f5f5f5;
        z-index: 10;
        text-transform: capitalize;
        margin-top: 15px;
      }
    }
  }
  &-table-action-item {
    color: white !important;
    background-color: #4c4ce2 !important;
    display: flex !important;
    justify-content: space-between !important;
    margin: 5px 5px !important;
    padding: 8px 8px 8px 12px !important;
    border-radius: 4px !important;
  }
  &-table-action-icon {
    color: #4c4ce2 !important;
    background-color: white !important;
    border-radius: 50px;
    padding: 4px;
    font-size: 10px !important;
    margin-left: 10px;
  }
  &-table-action-menu {
    .MuiPaper-root {
      zoom: var(--page-zoom);
      background-color: white !important;
      box-shadow: -1px 2px 2px 0px #6e6e6e !important;
      left: auto !important;
      right: 50px !important;
      ul {
        padding: 0;

        li.cash-allocation-table-action-item {
          background-color: #ff5a01 !important;
        }
      }
    }
  }
  &-dialogue-container {
    zoom: var(--page-zoom);
  }
}
@media screen and (min-width: 1920px) {
  body {
    // --page-zoom: 1;
  }
}
