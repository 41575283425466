.ForgotPasswordBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(15vh - 38px);
    margin-right: 30px;
}

.ForgotPasswordImage {
    height: 70vh;
    width: 35vw;
    border-radius: 10px 0px 0px 10px;

    @media (min-width: 768px) {
        height: 70vh;
        width: 45vw;
    }

    @media (min-width: 992px) {
        height: 70vh;
    }

    @media (min-width: 1024px) {
        height: 70vh;
        width: 35vw;
    }
}

.ResetPasswordSuccessBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 60px 10px;
    background-color: #FAFAFA;
    border-radius: 0px 10px 10px 0px;
    height: 70vh;
    width: 35vw;

    @media (min-width: 768px) {
            height: 70vh;
            width: 45vw;
        }
    
        @media (min-width: 1024px) {
            height: 70vh;
            width: 40vw;
            padding: 110px 10px;
        }
    }

.successIcon {
    background-color: green;
    border-radius: 50%;
}

.successText {
    font-size: 15px;
    text-align: justify;
}

.forgotSectionDialogOk {
    background-color: #FF5A01;
    padding: 1em 4em;
    border-radius: 20px !important;
    margin: 10px auto !important;
}

.ForgotPasswordMainPageBox {
    background-color: #FAFAFA;
    border-radius: 0px 10px 10px 0px;
    height: 70vh;
    width: 35vw;
    @media (min-width: 768px) {
            height: 70vh;
            width: 45vw;
        }
                @media (min-width: 992px) {
                    height: 70vh;
                }
        
                @media (min-width: 1024px) {
                    height: 70vh;
                    width: 35vw;
                }
}

.ForgotPasswordText {
    color: #FF5A01;
    font-size: 30px;
    line-height: 49.72px;
    font-weight: 300;
    padding-left: 20px;
}

.ValidationForms {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: auto;
    height: 56px;
}

.enterOtpField {
    color: black;
    background-color: white;
    width: 80%;
}

.forgotSecCancelBtn {
    height: 40px !important;
    width: 38% !important;
    padding: 8px 16px !important;
    color: #17191B !important;
    background-color: white !important;
    border-radius: 20px !important;
    border-color: black !important;
    text-transform: none !important;
    font-family: Orgon-Bold !important;
    font-size: 16px !important;
}

.forgotSecSubmitBtn {
    height: 40px !important;
    width: 38% !important;
    padding: 8px 16px !important;
    border-radius: 20px !important;
    text-transform: none !important;
    font-family: Orgon-Bold !important;
    font-size: 16px !important;
}
