.navbar {
    color: #fff;
    padding: 0px 15px;
    width: 100vw;
    max-height: 72px !important;
    height: 72px;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1a1a1a;

    .navBarContainer {
        display: flex !important;
        margin-right: 20px;
        text-decoration: none;
        color: white;
        display: flex;
        height: 72px;
        align-items: center;

        .navLink {
            padding: 0 12px;
            height: 100%;
            box-sizing: border-box;
            display: grid;
            align-items: center;
            text-align: center;
        }

        .activeLinkStyle {
            font-weight: 500;
            color: white;
            background-color: #000000;
            border-bottom: 4px solid #FF5A01;
        }
    }

    .navBarRightContainer {
        display: flex;
        justify-content: flex-start;
        padding-right: 30px;

        .curvedMenuButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            border-radius: 24px;
            padding: 5px 6px;
            background-color: #313539;
            margin-right: 5px;

            .userIcon {
                height: 30px !important;
                width: 30px !important;
                margin-right: 5px !important;
            }

            .userName {
                color: white;
                width: auto;
                max-width: 300;
            }

            .WhiteArrowDropDownIcon {
                color: white;
                padding: 5px
            }

        }

        .logoutButton {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background-color: #313539;
        }
    }

}